import React, { FC } from 'react';
import {
  SelectInput,
  SimpleForm,
  SimpleFormProps,
  TextInput,
  useRecordContext,
} from 'react-admin';

const InterSeasonForm: FC<Omit<SimpleFormProps, 'children'>> = (props) => {
  const record = useRecordContext();

  return (
    <SimpleForm {...props}>
      {record && record.id && (
        <TextInput
          source="questId"
          label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              Quest ID{' '}
              <span style={{ fontSize: '12px', marginLeft: '5px' }}>
                (Auto generated if not provided)
              </span>
            </div>
          }
          fullWidth
        />
      )}
      <TextInput source="titleStory" label="Title Story" fullWidth required />
      <TextInput
        source="messageStory"
        label="Message Story"
        fullWidth
        required
        multiline
      />
      <TextInput
        source="titleConclusion"
        label="Title Conclusion"
        fullWidth
        required
      />
      <TextInput
        source="firstButtonMessage"
        label="First Button Message"
        fullWidth
        required
      />
      <TextInput
        source="firstMessageConclusion"
        label="First Message Conclusion"
        fullWidth
        required
        multiline
      />
      <TextInput
        source="secondButtonMessage"
        label="Second Button Message"
        fullWidth
        required
      />
      <TextInput
        source="secondMessageConclusion"
        label="Second Message Conclusion"
        fullWidth
        required
        multiline
      />
      <TextInput
        source="thirdButtonMessage"
        label="Third Button Message"
        fullWidth
      />
      <TextInput
        source="thirdMessageConclusion"
        label="Third Message Conclusion"
        fullWidth
        multiline
      />
      <SelectInput
        source="status"
        label="Status"
        emptyText="Ready"
        emptyValue="Ready"
        choices={[
          { id: 'Active', name: 'Active' },
          { id: 'Finished', name: 'Finished' },
        ]}
        defaultValue={'Ready'}
        fullWidth
      />
    </SimpleForm>
  );
};

export default InterSeasonForm;
